// https://github.com/validatorjs/validator.js/tree/master/src/lib
import _isEmpty from "lodash/isEmpty";
/* eslint-disable-next-line */
const EMAIL_REGEXP = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
const int = /^(?:[-+]?(?:0|[1-9][0-9]*))$/;
const intLeadingZeroes = /^[-+]?[0-9]+$/;
const numeric = /^[+-]?([0-9]*[.])?[0-9]+$/;
const numericNoSymbols = /^[0-9]+$/;
const decimal = {
  "en-US": ".",
  ar: "٫"
};

function assertString(input) {
  const isString = typeof input === "string" || input instanceof String;

  if (!isString) {
    let invalidType;
    if (input === null) {
      invalidType = "null";
    } else {
      invalidType = typeof input;
      if (
        invalidType === "object" &&
        input.constructor &&
        input.constructor.hasOwnProperty("name")
      ) {
        invalidType = input.constructor.name;
      } else {
        invalidType = `a ${invalidType}`;
      }
    }
    throw new TypeError(`Expected string but received ${invalidType}.`);
  }
}

export function toUpperCase(value) {
  return value ? value.toUpperCase() : value;
}

export function isEmailValid(email) {
  return EMAIL_REGEXP.test(email);
}

export function isMultiEmailValid(email) {
  const _email = email.split(",");
  const isValid = _email.filter(item => !EMAIL_REGEXP.test(item));
  return _isEmpty(isValid);
}

export function isBoolean(str) {
  assertString(str);
  return ["true", "false", "1", "0"].indexOf(str) >= 0;
}

export function toBoolean(str, strict) {
  assertString(`${str}`);
  if (strict) {
    return str === "1" || /^true$/i.test(str);
  }
  return str !== "0" && !/^false$/i.test(str) && str !== "";
}

export function isInt(str, options) {
  assertString(str);
  options = options || {};

  // Get the regex to use for testing, based on whether
  // leading zeroes are allowed or not.
  let regex =
    options.hasOwnProperty("allow_leading_zeroes") &&
    !options.allow_leading_zeroes
      ? int
      : intLeadingZeroes;

  // Check min/max/lt/gt
  let minCheckPassed = !options.hasOwnProperty("min") || str >= options.min;
  let maxCheckPassed = !options.hasOwnProperty("max") || str <= options.max;
  let ltCheckPassed = !options.hasOwnProperty("lt") || str < options.lt;
  let gtCheckPassed = !options.hasOwnProperty("gt") || str > options.gt;

  return (
    regex.test(str) &&
    minCheckPassed &&
    maxCheckPassed &&
    ltCheckPassed &&
    gtCheckPassed
  );
}

export function isFloat(str, options) {
  assertString(str);
  options = options || {};
  const float = new RegExp(
    `^(?:[-+])?(?:[0-9]+)?(?:\\${
      options.locale ? decimal[options.locale] : "."
    }[0-9]*)?(?:[eE][\\+\\-]?(?:[0-9]+))?$`
  );
  if (str === "" || str === "." || str === "-" || str === "+") {
    return false;
  }
  const value = parseFloat(str.replace(",", "."));
  return (
    float.test(str) &&
    (!options.hasOwnProperty("min") || value >= options.min) &&
    (!options.hasOwnProperty("max") || value <= options.max) &&
    (!options.hasOwnProperty("lt") || value < options.lt) &&
    (!options.hasOwnProperty("gt") || value > options.gt)
  );
}

export function toInt(str, radix) {
  assertString(str);
  return parseInt(str, radix || 10);
}

export function toFloat(str) {
  if (!str || str === 'null') {
    return 0;
  }
  if (!isFloat(str)) return NaN;

  return parseFloat(str);
}

export function isNumeric(str, options) {
  assertString(str);
  if (options && options.no_symbols) {
    return numericNoSymbols.test(str);
  }
  return numeric.test(str);
}

export function toCapitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
